import "./style.sass"
import {ReactNode} from "react";
import {Link} from "react-router-dom";

interface CardProps {
    children?: ReactNode,
    className?: string,
    href?: string
}

const CardCmp = ({children, className, href}: CardProps) => {
    return (
        href
            ? <Link to={href} className={`card-cmp ${className}`}>{children}</Link>
            : <div className={`card-cmp ${className}`}>
                {children}
            </div>
    )
}

export default CardCmp;