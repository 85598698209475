import "./style.sass"
import TreeCmp, {TreeItem} from "../../../components/base/tree-cmp/tree-cmp";
import React, {ReactNode, useCallback, useState} from "react";
import {NODES_DATA} from "../MOCK_DATA";
import {createColumnHelper} from "@tanstack/react-table";
import {CategoryDto, ProjectFileDto, queryKeys, service, UploadTestFileDto} from "../../../utils/api/service";
import {TableCmp} from "../../../components/base/table-cmp/TableCmp";
import CardCmp from "../../../components/base/card-cmp/card-cmp";
import {SearchMode, SearchPanel, SearchParams} from "../data-tab/search-panel";
import {compareTwoString, isHasChildren} from "../data-tab/data-tab";
import {useParams, useSearchParams} from "react-router-dom";
import LoaderCmp from "../../../components/base/loader-cmp/loader-cmp";
import DropdownCmp from "../../../components/base/dropdown-cmp/dropdown-cmp";
import {Accept, FileWithPath, useDropzone} from "react-dropzone";
import {ButtonCmp} from "../../../components/base/button-cmp/button-cmp";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useNotification} from "../../../components/base/notification/notification-provider";
import HintIconCmp from "../../../components/base/hint-icon-cmp/hint-icon-cmp";
import TabsCmp from "../../../components/base/tabs-cmp/tabs-cmp";
import BadgeCmp from "../../../components/base/badge/badge-cmp";

interface Props {
    categories?: CategoryDto[],
    files?: ProjectFileDto[]
}

enum FilesDataSearchMode {
    Category = 1,
    File = 2
}

const PrimaryDataSearchModes: SearchMode[] = [
    { value: FilesDataSearchMode.Category, label: "По категориям" },
    { value: FilesDataSearchMode.File, label: "По файлам"}
]

export enum ContentType {
    FileDetails = "file-details",
    LoadData = "load-data",
    Null = "null"
}

const contentParamKey = "c";

const FilesTab = ({categories, files}: Props) => {

    const [params, setParams] = useSearchParams()
    const [filesSearchParams, setFilesSearchParams] = useState<SearchParams>({string: "", mode: FilesDataSearchMode.File})

    const currentContentType = params.get(contentParamKey) as ContentType || ContentType.LoadData;

    const setContentParams = (contentType: ContentType) => {
        setParams(searchParams => {
            searchParams.set(contentParamKey, contentType !== ContentType.Null ? contentType : "");
            return searchParams;
        });
    }

    const ContentTypeOptions: Record<ContentType, ReactNode> = {
        [ContentType.FileDetails]: <FileDetails/>,
        [ContentType.LoadData]: <LoadData categories={categories}/>,
        [ContentType.Null]: <h5>Выберите файл</h5>
    }

    const ContentTypeDeep: Record<number, ContentType> = {
        0: ContentType.Null,
        1: ContentType.FileDetails
    }

    const onSelectFile = useCallback((value: string, deep: number) => {
        if (deep) setContentParams(ContentTypeDeep[deep])
        // eslint-disable-next-line
    }, [setContentParams])

    return (
        <div className={"files-tab"}>
            <CardCmp className={"files-tab__tree"}>
                <TabsCmp
                    items={[{key: ContentType.LoadData, title: "Загрузить данные"}]}
                    selectedTab={currentContentType}
                    onSelect={(key) => setContentParams(key as ContentType)}
                    borderAlign={"top"}
                />
                <SearchPanel
                    params={filesSearchParams}
                    searchModes={PrimaryDataSearchModes}
                    setParams={(params) => setFilesSearchParams({...params})}
                />
                <TreeCmp
                    items={(files && categories) ? parseProjectFilesToTreeData(files, categories, filesSearchParams) : []}
                    onSelect={onSelectFile}
                />
            </CardCmp>
            <div>
                { ContentTypeOptions[currentContentType] }
            </div>
        </div>
    )
};

const FileDetails = () => {
    return (
        <CardCmp className={"file-details"}>
            <h4>Информация о файле</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
            <TableCmp columns={columns} data={NODES_DATA}/>
        </CardCmp>
    )
}

interface LoadDataProps {
    categories?: CategoryDto[]
}

const MAX_COUNT_FILES = 5;
const MAX_BYTES_FILES = 3221225472;

const LoadData = ({categories}: LoadDataProps) => {

    const queryClient = useQueryClient();
    const {toastSuccess, toastError} = useNotification();
    const {id: projectId} = useParams()

    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined)
    const [loadedFiles, setLoadedFiles] = useState<File[]>([])

    const {mutate: uploadFile,isPending} = useMutation({
        mutationFn: (data: UploadTestFileDto) => service.uploadTestFile(data),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: queryKeys.projectFiles(projectId)})
            queryClient.invalidateQueries({queryKey: queryKeys.projectTests(projectId)})
            queryClient.invalidateQueries({queryKey: queryKeys.projectPrimary(projectId)})
            queryClient.invalidateQueries({queryKey: queryKeys.projectData(projectId)})
            toastSuccess("Файл загружен")
        },
        onError: () => {
            toastError("Ошибка загрузки")
        }
    });

    const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} = useDropzone({
        accept: getAcceptedFileExtensionsMIME(getAcceptedFileExtensions(categories || [], selectedCategory || "")),
        onDrop: (files: File[]) => {
            const filteredFiles = files.filter(file => !loadedFiles.find(_file => file.name === _file.name))

            if (filteredFiles.length !== files.length)
                toastError("Данные файлы уже есть в списке")

            setLoadedFiles([...loadedFiles, ...filteredFiles])
        },
        onDropRejected: () => {
            toastError("Недопустимое расширение файла")
        }
    });

    const selectCategory = (name: string) => {
        setSelectedCategory(name)
        setLoadedFiles([])
    }

    const onUpload = () => {
        selectedCategory &&
        uploadFile({
            projectID: projectId || "",
            category: selectedCategory,
            files: loadedFiles
        })
        setLoadedFiles([]);
    }

    return (
        <CardCmp className={"load-data"}>
            <h5>Загрузка файлов</h5>
            {
                isPending ? <LoaderCmp/> :
                    <>
                        <DropdownCmp
                            items={getCategoriesItems(categories || [])}
                            defaultValue={selectedCategory}
                            placeholder={"Выберите категорию"}
                            onSelect={(key: string) => selectCategory(key)}
                        />
                        <div className={"dropzone-container"}>
                            {
                                selectedCategory ?
                                    <>
                                        <div {...getRootProps({className: `dropzone-container__upload ${(isDragAccept || isFocused || isDragReject) ? "focused" : ""}`})} >
                                            <input {...getInputProps()}/>
                                            <svg width="32" height="32" viewBox="0 -2 30 30" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(-569.000000, -674.000000)">
                                                    <path d="M579.732,681.7 L583,677.74 L583,691.998 C583,692.552 583.447,693 584,693 C584.553,693 585,692.552 585,691.998 L585,677.702 L588.299,681.7 C588.69,682.095 589.326,682.095 589.719,681.7 C590.11,681.307 590.11,680.668 589.719,680.274 L584.776,674.283 C584.566,674.073 584.289,673.983 584.016,673.998 C583.742,673.983 583.465,674.073 583.256,674.283 L578.313,680.274 C577.921,680.668 577.921,681.307 578.313,681.7 C578.705,682.095 579.341,682.095 579.732,681.7 L579.732,681.7 Z M598,690 C597.447,690 597,690.448 597,691 L597,698 L571,698 L571,691 C571,690.448 570.553,690 570,690 C569.447,690 569,690.448 569,691 L569,699 C569,699.553 569.447,700 570,700 L598,700 C598.553,700 599,699.553 599,699 L599,691 C599,690.448 598.553,690 598,690 L598,690 Z" />
                                                </g>
                                            </svg>
                                            <span>Выберите или перетащите файлы с расширением <br/>{getAcceptedFileExtensions(categories || [], selectedCategory).map((ext, index, arr) => <><span key={ext}>{ext}</span>{index < arr.length - 1 ? ", " : ""}</>)} в поле</span>
                                        </div>
                                        <div className='dropzone-container__limitations-container'>
                                            <BadgeCmp type={!isValidCountFiles(loadedFiles.length) ? "error" : "primary"}>
                                                {loadedFiles.length}/{MAX_COUNT_FILES} файлов
                                            </BadgeCmp>
                                            <BadgeCmp type={!isValidSizeFiles(loadedFiles) ? "error" : "primary"}>
                                                {byteToSize(loadedFiles.map(file => file.size)
                                                    .reduce((partialSum, a) => partialSum + a, 0))}
                                                /{byteToSize(MAX_BYTES_FILES)}
                                            </BadgeCmp>
                                        </div>
                                    </> : undefined
                            }
                            <div className='dropzone-container__files-list'>
                                {
                                    loadedFiles.map((file: FileWithPath, index) => (
                                        <div key={index}>
                                            <span>{file.path}</span>
                                            <div>
                                                <BadgeCmp type={"primary"}>{byteToSize(file.size)}</BadgeCmp>
                                                <svg
                                                    width="12" height="12" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                                    onClick={() => {
                                                        setLoadedFiles([...loadedFiles.filter(_file => _file.name !== file.name)])
                                                    }}
                                                >
                                                    <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {
                            loadedFiles.length ?
                                <div className={"load-data__button-container"}>
                                    <ButtonCmp onClick={onUpload} disabled={!isValidCountFiles(loadedFiles.length) || !isValidSizeFiles(loadedFiles)}>Загрузить</ButtonCmp>
                                    <ButtonCmp onClick={() => setLoadedFiles([])} type={"secondary"}>Отмена</ButtonCmp>
                                </div>
                                : null
                        }
                    </>
            }
        </CardCmp>
    )
}

function getCategoriesItems(categories: CategoryDto[]) {
    return categories.map((category, index) => {return {
        key: category.name,
        title: category.name,
        prefix: <HintIconCmp text={category.description} direction={index < categories.length - 1 ? "bottomLeft" : "topLeft"}/>
    }})
}

function isValidCountFiles(currentCount: number) {
    return currentCount <= MAX_COUNT_FILES
}

function isValidSizeFiles(files: File[]) {
    return files.map(file => file.size).reduce((partialSum, a) => partialSum + a, 0) <= MAX_BYTES_FILES
}

function getAcceptedFileExtensions(categories: CategoryDto[], targetCategory: string): string[] {
    const targetCategoryExtensions = categories?.find(category => category.name === targetCategory)?.extensions_files
    if (targetCategoryExtensions?.length)
        return targetCategoryExtensions?.join(",").split(",")
    return [];
}

function getAcceptedFileExtensionsMIME(extensions: string[]): Accept | undefined {
    if (extensions.length)
        return { "application/octet-stream": extensions}
    else return undefined
}

function byteToSize(bytes: number) {
    if (bytes === 0) return '0 B';
    const k = 1024;
    let units = ['B', 'KB', 'MB', 'GB'];
    let digit = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, digit)).toFixed(2)) + ' ' + units[digit];
}

interface NodeType {
    name: string,
    type: string,
    attribute: string
}

const columnHelper = createColumnHelper<NodeType>()

const columns = [
    columnHelper.accessor("name", {
        header: () => "Полное название",
        cell: (props) => props.getValue()
    }),
    columnHelper.accessor("type", {
        header: () => "Тип узла",
        cell: (props) => props.getValue()
    }),
    columnHelper.accessor("attribute", {
        header: () => "Аттрибут уникальности",
        cell: (props) => props.getValue()
    })
]

function parseProjectFilesToTreeData(files: ProjectFileDto[], categories: CategoryDto[], search: SearchParams) {

    const parseCategories = (categories: CategoryDto[]) => {

        let categoriesRaw = categories.filter(category =>
            files.some(file => file.category === category.name)
        )

        categoriesRaw = search.mode === FilesDataSearchMode.Category
            ? categoriesRaw.filter(category => compareTwoString(category.name, search.string))
            : categoriesRaw

        return categoriesRaw.map(category => {
            return {
                value: category.name,
                label: `${category.name.toUpperCase()}`,
                children: parseFiles(files.filter(file => file.category === category.name))
            }
        })
    }

    const parseFiles = (files: ProjectFileDto[]) => {

        const filesRaw = search.mode === FilesDataSearchMode.File
            ? files.filter(files => compareTwoString(files.filename, search.string))
            : files

        return filesRaw.map(file => {
            return { value: file.file_id, label: file.filename }
        })
    }

    let treeData: TreeItem[] = [...parseCategories(categories)]

    if (!search.string.length) return treeData;

    const treeDataFiltered =
        isHasChildren({value: "", label: "", children: treeData}, 0, search.mode)

    return (treeDataFiltered?.children ? [...treeDataFiltered.children] : [])
}

export default FilesTab;