import React, {useEffect, useState} from "react";
import {useOutsideClick} from "../../../utils/hooks/use-outside-click";
import InputCmp from "../../../components/base/input-cmp/input-cmp";
import {CheckboxCmp} from "../../../components/base/checkbox-cmp/checkbox-cmp";

export type SearchMode = {
    value: number,
    label: string
}

export interface SearchParams {
    string: string,
    mode: number
}

interface SearchPanelProps {
    params: SearchParams,
    searchModes?: SearchMode[]
    setParams(params: SearchParams): void
}

export const SearchPanel = ({params, searchModes, setParams}: SearchPanelProps) => {

    const [open, setOpen] = useState(false);
    const ref = useOutsideClick(() => {
        setOpen(false)
    })

    useEffect(() => {
        if (searchModes)
            setParams({
                ...params,
                mode: searchModes[searchModes?.length - 1].value
            })
        // eslint-disable-next-line
    }, [searchModes, params])

    return (
        <div className={"data-search-input"}>
            <InputCmp
                label={"Поиск"}
                value={params.string}
                onChange={(value) => setParams({...params, string: value})}
            />
            {
                searchModes?.length ?
                    <div className={"data-search-input__settings"} ref={ref}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setOpen(!open)}>
                            <path d="M12 7L20 7" strokeLinecap="round"/>
                            <path d="M4 7L8 7" strokeLinecap="round"/>
                            <path d="M17 17L20 17" strokeLinecap="round"/>
                            <path d="M4 17L12 17" strokeLinecap="round"/>
                            <circle cx="10" cy="7" r="2" transform="rotate(90 10 7)" strokeLinecap="round"/>
                            <circle cx="15" cy="17" r="2" transform="rotate(90 15 17)" strokeLinecap="round"/>
                        </svg>
                        {
                            open ?
                                <div>
                                    <h5>Поиск:</h5>
                                    {
                                        searchModes?.map(mode =>
                                                <span key={mode.value}>
                                        <CheckboxCmp
                                            selected={params.mode === mode.value}
                                            onClick={() => setParams({...params, mode: mode.value})}
                                        />
                                        <label>{mode.label}</label>
                                    </span>
                                        )
                                    }
                                </div> : undefined
                        }
                    </div> : undefined
            }
        </div>
    )
}