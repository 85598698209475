import React, {useState} from "react";
import "./style.sass"
import {useAuth} from "../../../utils/providers/AuthProvider";
import {useOutsideClick} from "../../../utils/hooks/use-outside-click";
import {queryKeys, service} from "../../../utils/api/service";
import {useQuery} from "@tanstack/react-query";
import {Theme, useTheme} from "../../../utils/providers/ThemeProvider";


const AccountCmp = () => {

    const [isOpen, setIsOpen] = useState(false);
    const {toggleTheme, theme} = useTheme()
    const {signOut} = useAuth();
    const ref = useOutsideClick(() => {
        setIsOpen(false);
    });

    const {data: userProfile} = useQuery({
        queryKey: queryKeys.userProfile(),
        queryFn: () => service.getProfile(),
        select: ({data}) => data
    })

    return (
        <div className={"personal-account-dropdown"} ref={ref}>
            <div className={`personal-account-dropdown__panel ${isOpen && "personal-account-dropdown__panel_open"}`}
                 onClick={() => setIsOpen(!isOpen)}
            >
                <span>{userProfile?.name}</span>
                <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.51501 8.465L12 16.95L20.485 8.465L19.071 7.05L12 14.122L4.92901 7.05L3.51501 8.465Z"/>
                </svg>
            </div>
            {
                isOpen &&
                <div className={"personal-account-dropdown__list"}>
                    <ul>
                        <li onClick={toggleTheme}>
                            {
                                theme === Theme.Light ?
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={"stroke-icon"}>
                                    <circle cx="12" cy="12" r="5" strokeWidth="1.5"/>
                                    <path d="M12 2V4" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M12 20V22" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M4 12L2 12" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M22 12L20 12" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M19.7778 4.22266L17.5558 6.25424" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M4.22217 4.22266L6.44418 6.25424" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M6.44434 17.5557L4.22211 19.7779" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M19.7778 19.7773L17.5558 17.5551" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg> :
                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={"fill-icon"}>
                                        <path d="M21.0672 11.8568L20.4253 11.469L21.0672 11.8568ZM12.1432 2.93276L11.7553 2.29085V2.29085L12.1432 2.93276ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25V22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25C1.25 17.9371 6.06294 22.75 12 22.75V21.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75V1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75ZM15.5 14.25C12.3244 14.25 9.75 11.6756 9.75 8.5H8.25C8.25 12.5041 11.4959 15.75 15.5 15.75V14.25ZM20.4253 11.469C19.4172 13.1373 17.5882 14.25 15.5 14.25V15.75C18.1349 15.75 20.4407 14.3439 21.7092 12.2447L20.4253 11.469ZM9.75 8.5C9.75 6.41182 10.8627 4.5828 12.531 3.57467L11.7553 2.29085C9.65609 3.5593 8.25 5.86509 8.25 8.5H9.75ZM12 2.75C11.9115 2.75 11.8077 2.71008 11.7324 2.63168C11.6686 2.56527 11.6538 2.50244 11.6503 2.47703C11.6461 2.44587 11.6482 2.35557 11.7553 2.29085L12.531 3.57467C13.0342 3.27065 13.196 2.71398 13.1368 2.27627C13.0754 1.82126 12.7166 1.25 12 1.25V2.75ZM21.7092 12.2447C21.6444 12.3518 21.5541 12.3539 21.523 12.3497C21.4976 12.3462 21.4347 12.3314 21.3683 12.2676C21.2899 12.1923 21.25 12.0885 21.25 12H22.75C22.75 11.2834 22.1787 10.9246 21.7237 10.8632C21.286 10.804 20.7293 10.9658 20.4253 11.469L21.7092 12.2447Z"/>
                                    </svg>
                            }
                            Поменять тему
                        </li>
                        <li onClick={() => signOut()}>
                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill={"none"} className={"stroke-icon"} xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 12L13 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18 15L20.913 12.087V12.087C20.961 12.039 20.961 11.961 20.913 11.913V11.913L18 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16 5V4.5V4.5C16 3.67157 15.3284 3 14.5 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H14.5C15.3284 21 16 20.3284 16 19.5V19.5V19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Выйти
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}

export default AccountCmp;