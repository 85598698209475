import "./style.sass";
import React, {ReactNode, useCallback, useState} from "react";
import TreeCmp, {TreeItem} from "../../../components/base/tree-cmp/tree-cmp";
import {
    ProjectDataDomainDto,
    ProjectDataDto,
    ProjectDataNodeDto,
    ProjectDataTypeNodeDto, ProjectPrimaryDataDomainDto
} from "../../../utils/api/service";
import {useParams, useSearchParams} from "react-router-dom";
import {DomainData} from "./domain-data/domain-data";
import {TypeNodeData} from "./type-node-data/type-node-data";
import {NodeData} from "./node-data/node-data";
import {PrimaryData} from "./primary-data/primary-data";
import CardCmp from "../../../components/base/card-cmp/card-cmp";
import TabsCmp from "../../../components/base/tabs-cmp/tabs-cmp";
import {SearchMode, SearchPanel, SearchParams} from "./search-panel";

interface Props {
    data?: ProjectDataDto
}

export enum ContentType {
    Domain = "domain",
    TypeNode = "type-node",
    Node = "node",
    Primary = "primary",
    Null = "null"
}

export enum DataType {
    Nodes = "nodes",
    Primary = "primary"
}

const dataParamKey = "d"
const contentParamKey = "c";
const nodeParamKey = "n";

interface ContentTypeOptionsItem {
    title?: string,
    content?: ReactNode
}

enum NodeDataSearchMode {
    Domain = 1,
    TypeNode = 2,
    Node = 3
}

const NodeDataSearchModes: SearchMode[] = [
    { value: NodeDataSearchMode.Domain, label: "По доменам" },
    { value: NodeDataSearchMode.TypeNode, label: "По типам узлов" },
    { value: NodeDataSearchMode.Node, label: "По узлам" }
]

enum PrimaryDataSearchMode {
    Domain = 1,
    Data = 2
}

const PrimaryDataSearchModes: SearchMode[] = [
    { value: PrimaryDataSearchMode.Domain, label: "По доменам" },
    { value: PrimaryDataSearchMode.Data, label: "По данным"}
]

const DataTab = ({data}: Props) => {

    const [params, setParams] = useSearchParams()
    const {id: projectId} = useParams()
    const [dataSearchParams, setDataSearchParams] = useState<SearchParams>({string: "", mode: NodeDataSearchMode.Node})

    const currentContentType = params.get(contentParamKey) as ContentType || ContentType.Null;
    const currentDataType = params.get(dataParamKey) as DataType || DataType.Nodes;
    const selectedNode = params.get(nodeParamKey) as string || "";

    const setContentParams = (contentType: ContentType, nodeId: string) => {
        setParams(searchParams => {
            searchParams.set(contentParamKey, contentType !== ContentType.Null ? contentType : "");
            searchParams.set(nodeParamKey, nodeId);
            return searchParams;
        });
    }

    const setDataTypeParams = (dataType: DataType) => {
        setParams(searchParams => {
            searchParams.set(dataParamKey, dataType);
            return searchParams;
        });
    }

    const ContentTypeOptions: Record<ContentType, ContentTypeOptionsItem> = {
        [ContentType.Domain]: {
            title: "Домен",
            content: <DomainData selectedNode={selectedNode} projectId={projectId || ""}
                                 setContentParams={setContentParams}/>
        },
        [ContentType.TypeNode]: {
            title: "Тип узла",
            content: <TypeNodeData selectedNode={selectedNode} projectId={projectId || ""}
                                   setContentParams={setContentParams}/>
        },
        [ContentType.Node]: {
            title: "Узел",
            content: <NodeData selectedNode={selectedNode} projectId={projectId || ""}
                               setContentParams={setContentParams}/>
        },
        [ContentType.Primary]: {
            title: "Информация о первичных данных",
            content: <PrimaryData selectedNode={selectedNode} projectId={projectId || ""}
                                  setContentParams={setContentParams}/>
        },
        [ContentType.Null]: {
            content: <h5>Выберите узел</h5>
        }
    }

    const NodeContentTypeDeep: Record<number, ContentType> = {
        0: ContentType.Domain,
        1: ContentType.TypeNode,
        2: ContentType.Node
    }

    const PrimaryContentTypeDeep: Record<number, ContentType> = {
        0: ContentType.Null,
        1: ContentType.Primary
    }

    const onSelectNodes = useCallback((value: string, deep: number) => {
        setContentParams(NodeContentTypeDeep[deep], value)
        // eslint-disable-next-line
    }, [setContentParams, currentDataType, NodeContentTypeDeep])

    const onSelectPrimary = useCallback((value: string, deep: number) => {
        if (deep) setContentParams(PrimaryContentTypeDeep[deep], value)
        // eslint-disable-next-line
    }, [setContentParams, currentDataType, PrimaryContentTypeDeep])

    return (
        <div className={"data-tab"}>
            <CardCmp className={"data-tab__tree"}>
                <TabsCmp
                    borderAlign={"top"}
                    items={[
                        {key: DataType.Nodes, title: "Узлы данных"},
                        {key: DataType.Primary, title: "Первичные данные"},
                    ]}
                    selectedTab={currentDataType}
                    onSelect={key => setDataTypeParams(key as DataType)}
                />
                <SearchPanel
                    params={dataSearchParams}
                    searchModes={currentDataType === DataType.Nodes ? NodeDataSearchModes : PrimaryDataSearchModes}
                    setParams={(params) => setDataSearchParams({...params})}
                />
                <div>
                    <TreeCmp
                        className={currentDataType !== DataType.Nodes ? "data-tab__tree_hidden" : ""}
                        items={data ? parseNodeDataToTreeData(data, {
                            string: dataSearchParams.string,
                            mode: dataSearchParams.mode
                        }) : []}
                        onSelect={(value, deep) => onSelectNodes(value, deep)}
                        selectedValue={selectedNode}
                    />
                    <TreeCmp
                        className={currentDataType !== DataType.Primary ? "data-tab__tree_hidden" : ""}
                        items={data ? parsePrimaryDataToTreeData(data, {
                            string: dataSearchParams.string,
                            mode: dataSearchParams.mode
                        }) : []}
                        onSelect={(value, deep) => onSelectPrimary(value, deep)}
                        selectedValue={selectedNode}
                    />
                </div>
            </CardCmp>
            <CardCmp className={"data-tab__info"}>
                <h4>{ContentTypeOptions[currentContentType]?.title}</h4>
                {ContentTypeOptions[currentContentType]?.content}
            </CardCmp>
        </div>
    )
};

export interface ContentTypeProps {
    projectId: string,
    selectedNode: string,

    setContentParams(contentType: ContentType, nodeId: string): void
}

function parseNodeDataToTreeData(data: ProjectDataDto, search: SearchParams) {
    const parseDomains = (domains: ProjectDataDomainDto[]) => {

        const domainsRaw = search.mode === NodeDataSearchMode.Domain
            ? domains.filter(domain => compareTwoString(domain.name, search.string))
            : domains

        return domainsRaw.map(domain => {
            return {
                value: domain.name,
                label: `Домен "${domain.name}"`,
                children: parseTypeNodes(domain.type_nodes_list)
            }
        })
    }

    const parseTypeNodes = (typeNodes: ProjectDataTypeNodeDto[]) => {

        const typeNodesRaw = search.mode === NodeDataSearchMode.TypeNode
            ? typeNodes.filter(typeNode => compareTwoString(typeNode.name, search.string))
            : typeNodes

        return typeNodesRaw.map(typeNode => {
            return {
                value: typeNode.id,
                label: typeNode.name,
                children: parseNodes(typeNode.nodes, typeNode.id)
            }
        })
    }

    const parseNodes = (nodes: ProjectDataNodeDto[], typeNodeId: string) => {

        const nodesRaw = search.mode === NodeDataSearchMode.Node
            ? nodes.filter(node => compareTwoString(node.name, search.string))
            : nodes

        return nodesRaw.map(node => {
            return {value: `${node.id}_${typeNodeId}`, label: node.name}
        })
    }

    let treeData: TreeItem[] = [...parseDomains(data.domains)]

    if (!search.string.length) return treeData;

    const treeDataFiltered =
        isHasChildren({value: "", label: "", children: treeData}, 0, search.mode)

    return (treeDataFiltered?.children ? [...treeDataFiltered.children] : [])
}

export function compareTwoString(str1: string, str2: string) {
    if (!str2.length) return true;
    return str1.toLowerCase().indexOf(str2.toLowerCase()) !== -1
}

export function isHasChildren(treeItem: TreeItem, currentCategory: number, searchCategory: number): TreeItem | undefined {
    if (currentCategory === searchCategory - 1)
        return treeItem.children?.length ? treeItem : undefined

    const children = treeItem.children?.map(children => isHasChildren(children, currentCategory + 1, searchCategory))?.filter(children => !!children) as (TreeItem[] | undefined);

    if (children?.length)
        return {...treeItem, children}
    return undefined;
}

function parsePrimaryDataToTreeData(data: ProjectDataDto, search: SearchParams) {

    const parseDomains = (domains: ProjectPrimaryDataDomainDto[]) => {

        const domainsRaw = search.mode === PrimaryDataSearchMode.Domain
            ? domains.filter(domain => compareTwoString(domain.domain, search.string))
            : domains

        return domainsRaw.map(domain => {
            return {
                value: domain.domain,
                label: `Домен "${domain.domain}"`,
                children: parseData(domain.type_data)
            }
        })
    }

    const parseData = (primaryData: string[]) => {

        const primaryDataRaw = search.mode === PrimaryDataSearchMode.Data
            ? primaryData.filter(data => compareTwoString(data, search.string))
            : primaryData

        return primaryDataRaw.map(data => {
            return {
                value: data,
                label: data
            }
        })
    }

    let treeData: TreeItem[] = [...parseDomains(data.primary)]

    if (!search.string.length) return treeData;

    const treeDataFiltered =
        isHasChildren({value: "", label: "", children: treeData}, 0, search.mode)

    return (treeDataFiltered?.children ? [...treeDataFiltered.children] : [])
}

export default DataTab;