import "./style.sass"
import {service, TestListDto} from "../../../utils/api/service";
import {CheckboxCmp} from "../../../components/base/checkbox-cmp/checkbox-cmp";
import CardCmp from "../../../components/base/card-cmp/card-cmp";
import HintIconCmp from "../../../components/base/hint-icon-cmp/hint-icon-cmp";
import {ButtonCmp} from "../../../components/base/button-cmp/button-cmp";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import LoaderCmp from "../../../components/base/loader-cmp/loader-cmp";
import {useNotification} from "../../../components/base/notification/notification-provider";

interface Props {
    data?: TestListDto
}

export const TestTab = ({data}: Props) => {

    const {id: projectId} = useParams()
    const {toastSuccess, toastError} = useNotification();
    const [selectedTests, setSelectedTests] = useState<string[]>([])

    const {mutate: runTests,isPending} = useMutation({
        mutationFn: () => service.runTests(projectId || "", selectedTests),
        onSuccess: () => {
            toastSuccess("Тесты успешно запущены")
        },
        onError: () => {
            toastError("Ошибка запуска тестов")
        }
    });

    const onSelectTest = (testId: string) => {
        if (selectedTests.includes(testId))
            setSelectedTests([...selectedTests.filter(id => id !== testId)])
        else setSelectedTests([...selectedTests, testId])
    }

    const onSelectAllTests = () => {
        if (isSelectedAll())
            setSelectedTests([])
        else setSelectedTests(data ? [...data?.tests.map(test => test.id)] : [])
    }

    const isSelectedAll = () => {
        return data?.tests.map(test => test.id).length === selectedTests.length
    }

    return (
        <div className={"test-tab"}>
            <CardCmp>
                {
                    isPending ? <LoaderCmp/> :
                        <>
                            <div>
                                <ButtonCmp
                                    type={"secondary"}
                                    disabled={!selectedTests.length}
                                    onClick={() => {
                                        selectedTests.length && runTests()
                                    }}
                                >
                                    {`Запустить тестирование (${selectedTests.length})`}
                                </ButtonCmp>
                            </div>
                            <div className={"test-tab__tests-list"}>
                                <div>
                                    <CheckboxCmp selected={isSelectedAll()} onClick={onSelectAllTests}/>
                                    <span>Выбрать все тесты</span>
                                </div>
                                {
                                    data?.tests.map(test =>
                                        <div key={test.id}>
                                            <CheckboxCmp selected={selectedTests.includes(test.id)} onClick={() => onSelectTest(test.id)}/>
                                            <span>{test.name.replaceAll("_", " ")}</span>
                                            <HintIconCmp text={test.description}/>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                }
            </CardCmp>
        </div>
    )
}