import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./assets/style/index.sass"
import {QueryClientProvider} from "@tanstack/react-query";
import {AuthProvider} from "./utils/providers/AuthProvider";
import {NotificationProvider} from "./components/base/notification/notification-provider";
import {queryClient} from "./utils/api/api";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "./utils/providers/ThemeProvider";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider>
                <NotificationProvider>
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <App/>
                        </AuthProvider>
                    </QueryClientProvider>
                </NotificationProvider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);
